const servicesList = [
    {
        id: 1,
        title: {
            en: 'UX/UI design',
            ua: 'UX/UI Дизайн',
        },
        description: {
            en: 'We create intuitive and visually appealing interfaces that provide a comfortable user experience, improving interaction with the product and engaging users with websites and mobile applications.',
            ua: 'Ми створюємо інтуїтивно зрозумілі та візуально привабливі інтерфейси, які забезпечують комфортний користувацький досвід, покращують взаємодію з продуктом та залучають користувачів до використання веб-сайтів і мобільних додатків.',
        },
        value: {
            en: ['$ Individual calculation', '10-15 days'],
            ua: ['$ Індивідуальний розрахунок', '10-15 днів'],
        },
    },
    {
        id: 2,
        title: {
            en: 'WP, Shopify',
            ua: 'WP, Shopify',
        },
        description: {
            en: 'We create flexible websites on WordPress and effective eCommerce solutions on Shopify. Our services offer customization for any task, ensuring ease of management, high performance, and stability for your business growth and success.',
            ua: 'Ми створюємо гнучкі сайти на WordPress та ефективні eCommerce-рішення на Shopify. Надаємо можливість кастомізації під будь-які завдання, забезпечуючи зручність управління, високу продуктивність і стабільність для розвитку вашого бізнесу.',
        },
        value: {
            en: ['$ Individual calculation', '15-30 days'],
            ua: ['$ Індивідуальний розрахунок', '15-30 днів'],
        },
    },
    {
        id: 3,
        title: {
            en: 'Dev. on Next.js',
            ua: 'Розробка на Next.js',
        },
        description: {
            en: 'We build web applications using the Next.js framework with a focus on high performance optimization, scalability, and clean code architecture.',
            ua: 'Ми створюємо веб-додатки на фреймворку Next.js з акцентом на високій оптимізації продуктивності, масштабованості та чистій архітектурі коду.',
        },
        value: {
            en: ['$ Individual calculation', '15-30 days'],
            ua: ['$ Індивідуальний розрахунок', '15-30 днів'],
        },
    },
    {
        id: 4,
        title: {
            en: 'Turnkey Dev.',
            ua: 'Розробка пiд КЛЮЧ',
        },
        description: {
            en: 'We conduct competitor analysis and market research, create a prototype, and then develop a turnkey solution tailored to your goals. We ensure full functionality, unique design, and technical support at every stage.',
            ua: 'Проводимо аналіз конкурентів і дослідження ринку, створюємо прототип, а потім розробляємо рішення "під ключ" з урахуванням ваших цілей. Забезпечуємо повну функціональність, унікальний дизайн і технічну підтримку на всіх етапах.',
        },
        value: {
            en: ['$ Individual calculation', '30-60 days'],
            ua: ['$ Індивідуальний розрахунок', '30-60 днів'],
        },
    },
];

export { servicesList };
