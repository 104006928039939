import porfolio01 from './images/portf-1.png';
import porfolio02 from './images/portf-2.png';
import porfolio03 from './images/portf-3.png';
import porfolio04 from './images/portf-4.png';
import porfolio05 from './images/portf-5.png';

const projectsList = [
    {
        id: 1,
        title: {
            en: 'SETH MILOT',
            ua: 'SETH MILOT',
        },
        description: {
            en: 'Luxury Leather Goods Website',
            ua: 'Iнтернет-магазин розкішних шкіряних виробів у Нью-Йорку',
        },
        image: porfolio01,
        tags: {
            en: ['Development', 'Shopify', 'USA'],
            ua: ['Development', 'Shopify', 'USA'],
        },
        link: 'https://sethmilot.com/',
    },
    {
        id: 2,
        title: {
            en: 'SKYGEN',
            ua: 'SKYGEN',
        },
        description: {
            en: 'Wholesaler of Premium windows and doors',
            ua: 'Оптовий продавець вікон та дверей Преміум Класса у Канадi',
        },
        image: porfolio02,
        tags: {
            en: ['UX/UI', 'Development', 'Word Press', 'Canada'],
            ua: ['UX/UI', 'Development', 'Word Press', 'Canada'],
        },
        link: 'https://skygenwindows.com/commercial-page',
    },
    {
        id: 3,
        title: {
            en: 'PlayFusionLabs',
            ua: 'PlayFusionLabs',
        },
        description: {
            en: 'PlayFusionLabs',
            ua: 'PlayFusionLabs',
        },
        image: porfolio03,
        tags: {
            en: ['UX/UI', 'Development', 'Word Press', 'Ukraine'],
            ua: ['UX/UI', 'Development', 'Word Press', 'Ukraine'],
        },
        link: 'https://playfusionlabs.com/',
    },
    {
        id: 4,
        title: {
            en: 'Be An Art',
            ua: 'Be An Art',
        },
        description: {
            en: 'Premium photo shoot in Canada',
            ua: 'Преміальна фотосесія в Канаді',
        },
        image: porfolio04,
        tags: {
            en: ['UX/UI', 'Development', 'Next.js', 'Canada'],
            ua: ['UX/UI', 'Development', 'Next.js', 'Canada'],
        },
        link: 'https://be-an.art/',
    },
    {
        id: 5,
        title: {
            en: 'anywell',
            ua: 'anywell',
        },
        description: {
            en: 'Online store of face masks',
            ua: 'Інтернет-магазин масок для обличчя',
        },
        image: porfolio05,
        tags: {
            en: ['UX/UI', 'Development', 'Shopify', 'Ukraine'],
            ua: ['UX/UI', 'Development', 'Shopify', 'Ukraine'],
        },
        link: 'https://anywellhub.com/pages/new-franchise',
    },
];

export { projectsList };
