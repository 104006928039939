const faqList = [
    {
        id: 1,
        title: {
            en: 'Can I buy a ready -made site?',
            ua: 'Чи можна купити готовий сайт?',
        },
        content: {
            en: 'Yes, you can purchase a ready-made template and customize it to your needs. This will be significantly cheaper than building a website from scratch and will save development time.',
            ua: "Так, ви можете купити готовий шаблон і налаштувати його під свої потреби. Це буде значно дешевше, ніж створення сайту з нуля, і дозволить заощадити час на розробку.",
        },
    },
    {
        id: 2,
        title: {
            en: 'Is it important that a studio in another city?',
            ua: 'Чи важливо, що студія в іншому місті?',
        },
        content: {
            en: "It doesn't matter. Web studios in Ukraine, the EU, and the USA work with clients from all regions and countries. We are no exception. One of our team members works remotely from New York. Communication is established through Zoom, email, and messengers.<br/> <br/> If the client is in the same city, a meeting is possible. However, it's often unnecessary.",
            ua: "Не має значення. Веб-студії України, ЄС та США працюють у всіх країнах і регіонах. Ми не виняток. Один наш співробітник працює віддалено з Нью-Йорка. Зв’язок вибудовується через Zoom, e-mail, месенджери.<br/><br/>Якщо клієнт знаходиться в тому ж місті, є можливість зустрічі. Але в цьому часто немає потреби.",
        },
    },
    {
        id: 3,
        title: {
            en: 'How much does the site cost and how much time does it make?',
            ua: 'Скільки коштує сайт і скільки часу його роблять?',
        },
        content: {
            en: 'The cost and timeline for building a website depend on many factors: project complexity, number of pages, functionality requirements, and design. Therefore, the exact price and time frame can only be determined after discussing all the details. Every project is unique, and we offer a tailored solution for each client.',
            ua: "Вартість і терміни створення сайту залежать від багатьох факторів: складності проєкту, кількості сторінок, функціональних вимог і дизайну. Тому точну ціну і час розробки можна визначити тільки після обговорення всіх деталей. Кожен проєкт індивідуальний, і для кожного клієнта ми пропонуємо унікальне рішення.",
        },
    },
];

export { faqList };
